<template>
  <v-card class="my-3">
    <v-card-title> Atuações </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="add">
        <v-text-field
          label="Adicionar atuação"
          hint="Pressione ENTER para adicionar"
          v-model="procedureToAdd"
        />
        <div class="mt-3">
          <template v-if="procedures.length > 0">
            <v-chip
              v-for="(procedure, index) in procedures"
              :key="index"
              close
              @click:close="remove(index)"
              class="mr-1 mb-1"
            >
              {{ procedure.name }}
            </v-chip>
          </template>
          <template v-else>
            <p class="font-italic">Nenhuma atuação adicionada.</p>
          </template>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProceduresForm",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    procedures: [],
    procedureToAdd: "",
  }),
  watch: {
    value(value) {
      console.log(value);
      if (value) {
        this.procedures = value;
      }
    },
  },
  mounted() {
    if (this.value) {
      this.procedures = this.value;
    }
  },
  methods: {
    add() {
      if (this.procedureToAdd) {
        this.procedures.push({
          name: this.procedureToAdd,
        });
        this.$emit("input", this.procedures);
        this.procedureToAdd = "";
      }
    },
    remove(index) {
      this.procedures.splice(index, 1);
      this.$emit("input", this.procedures);
    },
  },
};
</script>

<style scoped></style>
