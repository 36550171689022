<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <perseu-card title="Formulário de Chakra">
      <v-form ref="form" slot="content">
        <v-text-field
          label="Nome *"
          v-model="chakra.name"
          :rules="[$rules.required]"
        />
        <div class="text-center">
          <v-btn color="secondary" @click="copyNames">
            Copiar nome para nome amigável
          </v-btn>
        </div>
        <v-text-field
          label="Nome amigável *"
          v-model="chakra.friendlyName"
          :rules="[$rules.required]"
        />
        <v-text-field
          label="Localização *"
          v-model="chakra.location"
          :rules="[$rules.required]"
        />
        <v-textarea
          outlined
          label="Explicação sobre"
          v-model.trim="chakra.explanation"
          maxlength="2000"
          counter
        />
        <v-textarea
          outlined
          label="Uso dos imãs em casa"
          v-model.trim="chakra.homeUse"
          maxlength="2000"
          counter
        />
        <procedures-form v-model="chakra.procedures" />
        <perseu-input-image label="Imagem" v-model="chakra.image" />
      </v-form>
      <div slot="actions" class="justify-center">
        <v-btn color="secondary" @click="close">Salvar</v-btn>
      </div>
    </perseu-card>
  </v-dialog>
</template>

<script>
import PerseuInputImage from "@/components/shared/PerseuInputImage";
import ProceduresForm from "@/components/Chakras/ProceduresForm";
export default {
  name: "FormChakra",
  components: { ProceduresForm, PerseuInputImage },
  data: () => ({
    chakra: {},
    dialog: false,
  }),
  methods: {
    create() {
      this.chakra = {};
      this.$refs?.form?.reset();
      this.dialog = true;
    },
    edit(chakraParam) {
      this.chakra = chakraParam;
      this.dialog = true;
    },
    close() {
      if (!this.isValid()) return;
      this.$emit("close", this.chakra);
      this.dialog = false;
    },
    isValid() {
      return this.$refs.form.validate();
    },
    copyNames() {
      this.chakra.friendlyName = this.chakra.name;
    },
  },
};
</script>

<style scoped></style>
